// @import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  padding: 0;
}
.modal-footer{
  padding: 10px;
}
html {
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.btn{
  margin-right: 10px;
}

::-webkit-scrollbar {
  display: none;
}

.sidenav {
  // top: 0;
  // left: 0;
  width: 15%;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #444;
  transition: 0.5s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.3);
  .logo {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
  }
  .pages {
    height: 100px;
    overflow: scroll;
    overflow-x: hidden;
  }
}
.w-85 {
  width: 85%;
}
.start-15 {
  left: 15%;
}

.main-content {
  position: fixed !important;
  // .navbar {
  //   padding: 0;
  //   .container-fluid {
  //     padding: 0;
  //   }
  // }

  #editor {
    border: 2px solid rgba(0, 0, 0, 0.3);
  }
}
.d-none{
  .main-content{
    position: relative !important;
  width: 93%;
  }
}
.nav {
  justify-content: space-between;
}
.gjs-pn-panel {
  display: inline-block;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    padding: 5px;
    z-index: 3;
}

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
}

.tab-content {
  display: contents;
}
// .gjs-block-category{
//   padding-top: 20px;
//     padding-bottom: 20px;
// }

#block {
  height: 100%;
  #blocks {
    height: 100%;

    .gjs-blocks-c {
      align-items: center;
      justify-content: center;
    }
    .gjs-block {
      justify-content: center;
    }
  }
}

/* Theming */
.navbar-light{
  background-color: #444;
}
.fa{
  color: white;
}
#trait-tab{
  background-color: #444;
}
#block-tab{
  background-color: #444;
}
#layer-tab{
  background-color: #444;
}
#style-tab{
  background-color: #444;
}
.nav-tabs{
  background-color: #444;

}
.gjs-one-bg {
  background-color: #444;
}
.container-fluid{
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
}

.btn {
  margin-right: 0px;
}
.modalChosetemplate{
  border: 1px solid #ddd;
width: 100%;
    /* border: white; */
    background: #444;
    padding: 10px;
    width: 100%;
    left: 0px;
}
.right-button{
  padding-top: 15px;
    padding-right: 10px;
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: black;
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: 0.375rem;
  --bs-pagination-hover-color: #0e0f11;
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: black;
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgb(88 90 94 / 25%);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: black;
  --bs-pagination-active-border-color: black;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none;
}
.product-card-container:hover button {
    opacity: 0.85;
    display: flex;
}
// .btn:hover {
//     background-color: #444;
//     // color: white;
//     // border: none;
// }
.btn:hover {
  // color: none;
  background-color:#444;
  border-color: white
  ;
}
.gjs-two-color {
  color: #ddd;
}
.btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: #131415;
  border-color: #202122;
}
.editbutton{
  color: black;
}
.pagination {
  padding-top: 30px;
}
.page-item{
  padding-right: 10px;
}
.searchbox{
  padding-left: 20px;
}
.searchblabelox{
  padding-right: 10px;
}
.button-container.inverted:hover {
  background-color: #000;
  border: none;
  color: #fff;
}
.sanit {
  background-color: #000;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Open Sans Condensed;
  font-size: 15px;
  font-weight: bolder;
  height: 50px;
  justify-content: center;
  letter-spacing: .5px;
  line-height: 50px;
  min-width: 165px;
  padding: 0 35px;
  text-transform: uppercase;
  width: auto;
}
.gjs-three-bg {
  background-color: #ddd;
  color: #fcf6f5ff;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #ddd;
}

.gjs-pn-btn {
  border: 1px solid #ddd;
}

// Customize Bootstrap CSS

.btn,
.nav-link,
.modal-content,
.form-control {
  border-radius: 0 !important;
}

.btn {
  .fa {
    color: #ddd;
  }
  &:hover {
    .fa {
      color: #fcf6f5ff;
    }
  }
}

/** Error **/
.error {
  .bg-body {
    min-height: 150px;
    max-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-weight: 600;
    }
  }
  .btn {
    border-radius: 40px !important;
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 700;
    min-width: 150px;
  }
}

.modal-content {
  border-radius: 0;
}
.modal-header {
  padding: 5px 20px;
  border-bottom: 1px solid #ffffff;
}
.modal-title {
  font-size:18px;
  font-family: 'Oswald', sans-serif;
}
.close {
  font-size: 1.5rem;
  text-shadow: 0 0 0 #fff;
  opacity: .8;
}

.container-filter {
margin-top: 0;
margin-right: 0;
margin-left: 0;
margin-bottom: 30px;
padding: 0;
text-align: center;
}
.categories :active{
  background-color: darkgoldenrod;
}
.container-filter li {
list-style: none;
text-transform: uppercase;
background-color: #ede8e1;
border-radius: 5px;
margin: 10px 0px;
}
.btn-close {
background-size:14px
}
.container-filter a {
display: block;
text-align: center;
font-size: 12px;
font-weight: 500;
color: #000000;
border: none;
cursor: pointer;
font-family: 'Poppins', sans-serif;
line-height: 20px;
text-decoration: none;
padding: 10px 30px;
width: 100%;
}

.container-filter a:hover {
color: #222222 !important;
}

.container-filter a.active {
// color: #222222 !important;
color: #fef2f2 !important;
background-color: black;
}

.item-box {
position: relative;
overflow: hidden;
display: block;
margin-bottom: 10px;
}

.item-box a {
display: inline-block;
}

.item-box .item-mask {
// background: none repeat scroll 0 0 rgba(255, 255, 255, 0.7);
background: #242222de;
position: absolute;
transition: all 0.5s ease-in-out 0s;
-moz-transition: all 0.5s ease-in-out 0s;
-webkit-transition: all 0.5s ease-in-out 0s;
-o-transition: all 0.5s ease-in-out 0s;
top: auto;
left: 10px;
bottom: 10px;
right: 10px;
opacity: 0;
visibility: hidden;
overflow: hidden;
text-align: center;  
height: 60px;
border-radius: 5px;
}
.img-fluid {
  max-width: 100%;
  // height: 180px;
}
.item-box .item-mask .item-caption {
position: absolute;
width: 100%;
bottom: 0px;
opacity: 0;
}
.closebutton{
  background-color: black;
    color: white;
}
.footer{
  margin-bottom: 10px;
    text-align: center;
}
.search-ox{
  width: 100%;
  padding: 5px;
  // height: 40px;
  // font-size: 19px;
}

.item-box:hover .item-mask {
opacity: 1;
visibility: visible;
cursor: pointer !important;
}

.item-box:hover .item-caption {
opacity: 1;
}

.item-box:hover .item-container {
width: 100%;
}

.services-box {
padding: 45px 25px 45px 25px;
}
h6.text-dark {
font-size: 13px;
font-family: 'Poppins', sans-serif;
margin-bottom: 5px;
}
p.text-dark {
font-size: 12px;
}

h6.text-white {
  font-size: 13px;
  font-family: 'Poppins', sans-serif;
 
  }
  p.text-white {
  font-size: 12px;
  padding-bottom: 5px;
  }

/** Assets **/
// .gjs-am-meta,
// .gjs-am-close,
// .gjs-am-file-uploader,
// .gjs-am-assets-header {
//   display: none;
// }

// .gjs-am-assets-cont {
//   width: 100%;
// }

.gjs-am-assets {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.gjs-am-asset-image {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: contents;
}

.gjs-am-preview-cont {
  margin-top: 1rem;
}
.gjs-block-label {
  .fa {
    font-size: 25px;
  }
}
.grp-wrapper {
  background-image: url("data:image/png:base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==");
}
.grp-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: crosshair;
}
.grp-handler {
  width: 4px;
  margin-left: -2px;
  user-select: none;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.grp-handler-close {
  color: rgba(0, 0, 0, 0.4);
  border-radius: 0 2px 10px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  text-align: center;
  width: 15px;
  height: 15px;
  margin-left: -5px;
  line-height: 10px;
  font-size: 21px;
  cursor: pointer;
}

.grp-handler-close {
  position: absolute;
  top: -17px;
}

.grp-handler-drag {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  width: 100%;
  height: 100%;
}

.grp-handler-selected .grap-handler-drag {
  background-color: rgba(255, 255, 255, 0.5);
}

.grp-handler-cp-c {
  display: none;
}

.grp-handler-selected .grp-handler-cp-c {
  display: block;
}

.grp-handler-cp-wrap {
  width: 15px;
  height: 15px;
  margin-left: -8px;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border-radius: 100%;
  cursor: pointer;
}

.grp-handler-cp-wrap input[type="color"] {
  opacity: 0;
  cursor: pointer;
}
